import React from 'react';
import PropTypes from 'prop-types';

import styles from './buttonDownload.module.scss';
import { Icon, Spinner } from '../..';

const ButtonDownload = ({ onClick, loading, type = 'xls', title, ...rest }) => (
  <button
    disabled={loading}
    className={styles.button}
    onClick={onClick}
    type="button"
    title={title || undefined}
    {...rest}
  >
    {loading ? (
      <Spinner />
    ) : (
      <div>
        <Icon name={type} classes={{ icon: styles.icon }} />
      </div>
    )}
  </button>
);

ButtonDownload.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['xls', 'xml']),
};

export default ButtonDownload;
