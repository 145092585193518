export const config = {
  API_BASE_URL_DEV: 'https://h2temp.herokuapp.com',
  API_BASE_URL_PROD: `${window.location.protocol}//${window.location.host}`,
  API_BASE_VERSION: '/api/v5',
  API_BASE_SOCKET_HOST_DEV: 'h2temp.herokuapp.com',
  API_BASE_SOCKET_HOST_PROD: window.location.host,
  API_BASE_SOCKET_PORT: null,
  API_ENDPOINTS: {
    login: '/session/login',
    verify2faCode: '/session/verify_2fa',
    impersonate: id => `/business_clients/${id}/impersonate`,
    logout: '/session/logout',
    updatePass: '/admins/update_password',
    resetPassRequest: '/admins/send_reset_token',
    resetPassFromToken: '/admins/reset_password',
    getCurrentAdmin: '/admins/me',
    admins: '/admins',
    adminsManagers: '/admins/managers',
    addAdmin: '/admins/add',
    getAdmin: id => `/admins/${id}`,
    editAdmin: id => `/admins/${id}`,
    searchHeader: params => `/lov/${params.model}/${params.field}`,
    clients: '/clients',
    getClient: id => `/clients/${id}`,
    deleteClientData: id => `/clients/${id}/anonymize`,
    updateClient: id => `/clients/${id}`,
    exportClients: '/clients/export',
    searchClients: query => `/clients/search?query=${query}&limit=5`,
    searchH2Cards: query => `/h2_cards/search?query=${query}&limit=5`,
    getClientCards: clientId => `/clients/${clientId}/payment_cards`,
    topUpWallet: clientId => `/clients/${clientId}/add_virtual_balance`,
    businessClients: '/business_clients',
    getBusinessClient: id => `/business_clients/${id}`,
    getBusinessClientMemberships: id => `/business_clients/${id}/memberships`,
    updateBusinessClient: id => `/business_clients/${id}`,
    addBusinessClient: '/business_clients/add',
    addBusinessClientsMembership: id =>
      `/business_clients/${id}/memberships/add`,
    updateBusinessClientsMembership: (clientId, membershipId) =>
      `/business_clients/${clientId}/memberships/${membershipId}`,
    locations: '/locations/income',
    addLocations: '/locations/add',
    editLocation: id => `/locations/${id}`,
    getLocation: id => `/locations/${id}`,
    updateLocation: id => `/locations/${id}`,
    searchCompanyDetails: query => `/company_details/search?query=${query}`,
    singleCompanyDetails: code => `/company_details/${code}`,
    countries: '/countries',

    exportLocations: '/locations/income/export',
    downloadLocationsQr: id => `/locations/${id}/qr`,
    discounts: '/discounts',
    addDiscount: '/discounts/add',
    getDiscount: id => `/discounts/${id}`,
    editDiscount: id => `/discounts/${id}`,
    deactivateDiscount: id => `/discounts/${id}`,
    exportDiscounts: '/discounts/export',
    uploadBannerImage: '/images/upload',
    orders: '/orders',
    getOrder: id => `/orders/${id}`,
    retryPayment: orderId => `/orders/${orderId}/retry_payment`,
    markAsPaid: orderId => `/orders/${orderId}/mark_as_paid`,
    exportOrders: '/orders/export',
    memberships: '/memberships',
    getMembership: id => `/memberships/${id}`,
    editMembership: id => `/memberships/${id}`,
    createMembership: '/memberships/add',
    addMembershipHistory: id => `/memberships/${id}/history`,
    editMembershipHistory: (id, historyId) =>
      `/memberships/${id}/history/${historyId}`,
    deleteMembershipHistory: (id, historyId) =>
      `/memberships/${id}/history/${historyId}`,
    exportMemberships: '/memberships/export',
    exportMembershipsClients: '/memberships/business_clients/export',
    membershipOrders: '/memberships/orders',
    getMembershipOrder: id => `/memberships/orders/${id}`,
    addMembershipOrderToClient: '/memberships/orders/add',
    deactivateMembershipOrder: id => `/memberships/orders/${id}`,
    exportMembershipOrders: '/memberships/orders/export',
    getServicestableData: '/services/income',
    exportServices: '/services/income/export',
    getBoxestableData: '/devices/income',
    regenerateKey: id => `/devices/${id}/key`,
    exportBoxes: '/devices/income/export',
    getInvoices: '/invoices',
    getInvoice: id => `/invoices/${id}`,
    exportInvoices: '/invoices/export',
    exportInvoicesAsync: '/invoices/exportAsync',
    exportClientsAsync: '/clients/exportAsync',
    exportOrdersAsync: '/orders/exportAsync',
    exportReceiptsAsync: '/receipts/exportAsync',
    exportInvoicesHeads: '/invoices/export/heads',
    exportInvoicesBodies: '/invoices/export/bodies',
    exportInvoicesPartners: '/invoices/export/partners',
    exportInvoicesXml: '/invoices/export/xml',
    generateMonthlyInvoices: '/invoices/generateMonthly',
    getReceipts: '/receipts',
    getReceipt: id => `/receipts/${id}`,
    exportReceipts: '/receipts/export',
    exportReceiptsXml: '/receipts/export/xml',
    pingDevice: '/devices/ping',
    fileUpload: '/files/upload',
    imageUpload: '/images/upload',
    updateInvoice: id => `/invoices/${id}`,
    downloadInvoicePdf: (id, locale) => `/invoices/${id}/pdf/${locale}`,
    getAutomaticWashCodes: '/automatic_wash_codes',
    useAutomaticWashCode: id => `/automatic_wash_codes/${id}/use`,
    cancelAutomaticWashCode: id => `/automatic_wash_codes/${id}/cancel`,
    getCoupons: '/coupons',
    exportCoupons: '/coupons/export',
    getCoupon: id => `/coupons/${id}`,
    exportCouponsCodeByCode: id => `/coupons/${id}/codes/export`,
    exportBusinessClients: '/business_clients/export',
    campaignNotifications: '/notification_campaigns',
    addCampaignNotification: '/notification_campaigns/add',
    getCampaignNotification: id => `/notification_campaigns/${id}`,
    editCampaignNotification: id => `/notification_campaigns/${id}`,
    campaignNotificationCountAudience: '/notification_campaigns/count_audience',
    objectNumbers: '/invoices/object_numbers',

    // Business admin
    getBaEmployees: '/business_client_employees',
    getBaEmployee: id => `/business_client_employees/${id}`,
    getH2Card: id => `/h2_cards/${id}`,
    addBaEmployee: '/business_client_employees/add',
    addH2Card: '/h2_cards/add',
    updateBaEmployee: id => `/business_client_employees/${id}`,
    updateH2Card: id => `/h2_cards/${id}`,
    getBaInvoices: '/invoices',
    getBaInvoice: id => `/invoices/${id}`,
    getBaOrderHistory: '/orders',
    getBaOrderDetails: id => `/orders/${id}`,
    bulkMemberships: '/business_client_employees/bulkMemberships',
    exportH2CardAsync: '/h2_cards/exportAsync/usage',
    exportEmployees: '/business_client_employees/export',
  },
  APP_PATHS: {
    // Admin
    home: '/',
    twoFactorAuthConfirmation: '/confirm',
    locations: '/locations',
    addWasher: '/locations/add-location',
    orders: '/orders',
    orderDetails: id => (id ? `/orders/${id}` : '/orders/:id'),
    ordersByClient: phone =>
      phone ? `/orders/client/${phone}` : '/orders/client/:phone',
    ordersByBusinessClient: business_client_id =>
      business_client_id
        ? `/orders/businessClient/${business_client_id}`
        : '/orders/businessClient/:business_client_id',
    clients: '/clients',
    clientsByBusinessClient: business_client_id =>
      business_client_id
        ? `/clients/businessClient/${business_client_id}`
        : '/clients/businessClient/:business_client_id',
    editClient: id => (id ? `/clients/${id}` : '/clients/:id'),
    businessClients: '/businessClients',
    addBusinessClient: '/businessClients/add',
    editBusinessClient: id =>
      id ? `/businessClients/${id}` : '/businessClients/:id',
    receipts: '/receipts',
    receiptDetails: id => (id ? `/receipts/${id}` : '/receipts/:id'),
    invoices: '/invoices',
    invoicesByBusinessClient: business_client_company_code =>
      business_client_company_code
        ? `/invoices/businessClient/${business_client_company_code}`
        : '/invoices/businessClient/:business_client_company_code',
    invoiceDetails: id => (id ? `/invoices/${id}` : '/invoices/:id'),
    membershipOrders: '/membership-orders',
    membershipOrderDetails: id =>
      id ? `/membership-orders/${id}` : '/membership-orders/:id',
    memberships: '/memberships',
    addMembership: '/memberships/add',
    editMembership: id => (id ? `/memberships/${id}` : '/memberships/:id'),
    discounts: '/discounts',
    addDiscount: '/discounts/add',
    editDiscount: id => (id ? `/discounts/${id}` : '/discounts/:id'),
    admins: '/admins',
    addAdmin: '/admins/add',
    editAdmin: id => (id ? `/admins/${id}` : '/admins/:id'),
    updatePassword: '/update-password',
    resetPassword: '/password_reset',
    editLocation: id => (id ? `/locations/${id}` : '/locations/:id'),
    ordersByLocation: location =>
      location ? `/orders/location/${location}` : '/orders/location/:location',
    textile: '/textile',
    coupons: '/coupons',
    couponDetails: id => (id ? `/coupons/${id}` : '/coupons/:id'),
    campaignNotifications: '/campaign-notifications',
    addCampaignNotification: '/campaign-notifications/add',
    editCampaignNotification: id =>
      id ? `/campaign-notifications/${id}` : '/campaign-notifications/:id',
    externalHelpdesk:
      'https://h2auto.atlassian.net/servicedesk/customer/portal/1',

    // Business Admin
    baEmployees: '/employees',
    addEmployee: '/baEmployees/add',
    baOrderHistory: '/history',
    baOrderHistoryByClient: phone =>
      phone ? `/history/client/${phone}` : '/history/client/:phone',
    baInvoices: '/companyInvoices',
    editEmployee: id => (id ? `/employees/${id}` : '/employees/:id'),
    editH2Card: id => (id ? `/h2_cards/${id}` : '/h2_cards/:id'),
    historicalOrderDetails: id => (id ? `/history/${id}` : '/history/:id'),
    baInvoiceDetails: id =>
      id ? `/companyInvoices/${id}` : '/companyInvoices/:id',
    baDiscountPlans: '/discount-plans',
    baDiscountPlan: (id = ':id') => `/discount-plans/${id}`,
  },
  LOCAL_STORAGE: {
    adminToken: 'adminToken',
    adminTokenSetupTime: 'adminTokenSetupTime',
    adminTokenExpiryHours: 120,
    impersonationToken: 'impersonationToken',
    isImpersonating: 'isImpersonating',
  },
  PERMISSIONS: {
    admins: {
      view: 1001,
      edit: 1002,
    },
    clients: {
      view: 1003,
      edit: 1004,
    },
    invoices: {
      view: 1005,
      edit: 1006,
    },
    membershipOrders: {
      view: 1007,
      edit: 1008,
    },
    memberships: {
      view: 1009,
      edit: 1010,
    },
    discounts: {
      view: 1011,
      edit: 1012,
    },
    orders: {
      view: 1013,
      edit: 1014,
    },
    locations: {
      view: 1015,
      edit: 1016,
    },
    receipts: {
      view: 1017,
      edit: 1018,
    },
    businessClients: {
      view: 1019,
      edit: 1020,
    },
    textile: {
      view: 1021,
    },
    coupons: {
      view: 1023,
      edit: 1024,
    },
  },
  BUSINESS_ROLES: {
    MANAGER: {
      name: 'Vadybininkas',
      value: 1301,
    },
  },
  PAGES: {
    // Admin
    admins: 'Administratoriai',
    locations: 'Lokacijos',
    orders: 'Užsakymai',
    clients: 'Klientai',
    businessClients: 'Verslo klientai',
    receipts: 'Kvitai',
    invoices: 'Sąskaitos faktūros',
    membershipOrders: 'Nuolaidų planų užsakymai',
    memberships: 'Nuolaidų planai',
    discounts: 'Nuolaidos',
    textile: 'Tekstilinė',
    coupons: 'Kuponai',
    campaignNotifications: 'Kampanijos',
    helpdesk: 'Pagalba',

    // Business admin
    baEmployees: 'Darbuotojai',
    baHistory: 'Istorija',
    baInvoices: 'Sąskaitos faktūros',
    baDiscountPlans: 'Nuolaidų planai',
  },
  DEFAULTS: {
    locale: 'lt',
    dateFormat: 'yyyy.MM.dd',
    recordsPerPage: 100,
    alertDuration: 5000,
    symbolsToSearch: 3,
    companyCodeSymbolsToSearch: 9,
  },
};
