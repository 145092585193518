import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './filterTitle.module.scss';
import { Icon } from '../../..';

const FilterTitle = ({ title, onTitleClick, isActive, customWidth }) => (
  <div
    className={cn(styles.title, isActive && styles.active)}
    onClick={onTitleClick}
    style={{
      ...(customWidth && {
        maxWidth: customWidth,
      }),
    }}
  >
    <span>{title}</span>
    <span>
      <Icon name="filter" classes={{ icon: styles.icon }} />
    </span>
  </div>
);

FilterTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onTitleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default FilterTitle;
