import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pdfjs, Document, Page } from 'react-pdf';
import { PaperNote } from '../../components';

import styles from './invoices.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import { isEmpty } from '../../utils';
import {
  WithSpinner,
  Spinner,
  Header,
  Breadcrumb,
  Paper,
  PaperNav,
  PaperContainer,
  Button,
  ButtonLink,
} from '../../components';
import InvoiceRecipient from './invoicesRecipient.edit';
import InvoicesEntries from './invoicesEntries.edit';
import { InvoicesContext } from './invoices.context';
import Tabs from '../../components/tabs/tabs';
import EditEntry from './invoiceEntry.edit';
import { validationErrors } from './invoices.details.validations';
import { INVOICE_STATUS_NOT_PAID, INVOICE_STATUS_PAID } from './constants';
import InvoicesInfo from './invoices.info.component';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const tabsContent = [
  {
    title: 'Sąskaita',
    tabIdx: 0,
  },
  {
    title: 'Paslaugų gavėjas',
    tabIdx: 1,
  },
  {
    title: 'Prekės',
    tabIdx: 2,
  },
];

const InvoiceDetails = ({
  dispatch,
  match: { params },
  invoices: { selectedInvoice, loading },
  dropdowns: { countries, objectNumbers },
}) => {
  const { activeTab, setActiveTab } = useContext(InvoicesContext);
  const [isPaid, setIsPaid] = useState(false);

  const invoiceEntries = useMemo(() => {
    return selectedInvoice?.entries
      ? selectedInvoice?.entries.map((entry, index) => {
          return { id: index + 1, ...entry };
        })
      : [];
  }, [selectedInvoice]);

  const isCompanyType = useMemo(() => {
    const recipient_info = selectedInvoice?.recipient_info;
    return !!recipient_info?.company_name;
  }, [selectedInvoice]);

  const isSynced = useMemo(() => {
    return selectedInvoice?.synced;
  }, [selectedInvoice]);

  const fetchInvoice = useCallback(
    id => {
      dispatch(actions.invoices.getInvoiceAction(id));
    },
    [dispatch]
  );

  const fetchCountries = useCallback(() => {
    dispatch(actions.dropdowns.getCountriesAction());
  }, [dispatch]);

  const fetchObjectNumbers = useCallback(() => {
    dispatch(actions.dropdowns.getObjectNumbersAction());
  }, [dispatch]);

  const clearInvoices = useCallback(() => {
    dispatch(actions.invoices.clearInvoicesAction());
  }, [dispatch]);

  useEffect(() => {
    fetchInvoice(params.id);
    return () => {
      clearInvoices();
      setActiveTab(0);
    };
  }, [fetchInvoice, clearInvoices, params.id]);

  useEffect(() => {
    setIsPaid(selectedInvoice.status === INVOICE_STATUS_PAID);
  }, [selectedInvoice]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    fetchObjectNumbers();
  }, [fetchObjectNumbers]);

  const handlePaymentStatus = e => {
    e.preventDefault();

    const newStatus = !isPaid ? INVOICE_STATUS_PAID : INVOICE_STATUS_NOT_PAID;
    setIsPaid(!isPaid);
    dispatch(
      actions.invoices.updateInvoicePaymentStatusAction(
        selectedInvoice.id,
        newStatus
      )
    );
  };

  const handlePdfDownload = () => {
    dispatch(
      actions.invoices.downloadInvoicePdf(
        selectedInvoice.id,
        selectedInvoice.invoice_number
      )
    );
  };

  const handleRecipientEditSubmit = data => {
    const error = validationErrors(data, isCompanyType);

    if (error) dispatch(actions.error.setErrorAction({ msg: error }));
    else {
      const invoiceId = selectedInvoice.id;
      const body = {
        recipient_info: {
          ...data,
        },
      };
      dispatch(actions.invoices.updateInvoiceAction(invoiceId, body));
    }
  };

  const openModal = (entry = null) => {
    const invoiceId = selectedInvoice.id;
    const payload = {
      modalName: 'defaultModal',
      title: 'Redaguoti prekę',
      children: (
        <EditEntry
          invoiceId={invoiceId}
          entries={invoiceEntries}
          selectedEntry={entry}
          dispatch={dispatch}
          objectNumbers={objectNumbers}
        />
      ),
    };

    dispatch(actions.modals.setModalVisible(payload));
  };

  const invoiceView = () => {
    return (
      <Paper>
        <div className={styles.pdfContainer}>
          {selectedInvoice.pdf && (
            <Document
              file={`data:application/pdf;base64,${selectedInvoice.pdf}`}
              error="Klaida! Nepavyko užkrauti .pdf dokumento"
              noData="Dokumentas nerastas"
              loading={<Spinner />}
            >
              <Page className={styles.centeredPdf} pageNumber={1} />
            </Document>
          )}
        </div>
      </Paper>
    );
  };

  const recipientEditView = () => {
    const recipient_info = selectedInvoice?.recipient_info;
    const defaultValues = {
      company_code: recipient_info.company_code || '',
      company_name: recipient_info.company_name || '',
      billing_name: recipient_info.billing_name || '',
      tax_code: recipient_info.tax_code || '',
      address: recipient_info.address || '',
      city: recipient_info.city || '',
      postal_code: recipient_info.postal_code || '',
      country_code: recipient_info.country_code || 'LT',
      client_phone: recipient_info.client_phone || '',
    };
    return (
      <Paper>
        <InvoiceRecipient
          defaultValues={defaultValues}
          onSubmit={handleRecipientEditSubmit}
          countries={countries}
          isSynced={isSynced}
        />
      </Paper>
    );
  };

  const recipientEntriesView = () => {
    return (
      <Paper>
        <InvoicesEntries
          entries={invoiceEntries}
          openModal={openModal}
          isSynced={isSynced}
        />
      </Paper>
    );
  };

  const paperContent = () => {
    switch (activeTab) {
      case 0:
        return invoiceView();
      case 1:
        return recipientEditView();
      case 2:
        return recipientEntriesView();
      default:
        return null;
    }
  };

  return (
    <section>
      <Header>
        <div className={styles.leftHeaderItemsContainer}>
          <Breadcrumb
            title={config.PAGES.invoices}
            subtitle="Sąskaitos informacija"
            baseUrl={config.APP_PATHS.invoices}
          />
        </div>

        <Button color="outline" icon="download" onClick={handlePdfDownload}>
          Atsisiųsti
        </Button>
      </Header>
      <WithSpinner loading={loading}>
        <PaperContainer>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabsContent={tabsContent}
          >
            {paperContent()}
          </Tabs>
          {!isEmpty(selectedInvoice) && (
            <div>
              <PaperNote>
                <InvoicesInfo invoice={selectedInvoice} />
              </PaperNote>
              <PaperNav>
                {/* <Checkbox
                  label="Apmokėta"
                  name="is_paid"
                  checked={isPaid}
                  value={isPaid}
                  success={isPaid}
                  onChange={handlePaymentStatus}
                  disabled={
                    selectedInvoice.business_client_id === null ||
                    selectedInvoice.business_client_id === undefined
                  }
                /> */}
                <ButtonLink
                  icon="nav-orders"
                  url={config.APP_PATHS.orderDetails(selectedInvoice.order_id)}
                >
                  Užsakymas
                </ButtonLink>
                <ButtonLink
                  icon="nav-clients"
                  url={config.APP_PATHS.editClient(selectedInvoice.client.id)}
                >
                  {selectedInvoice.client.phone}
                </ButtonLink>
                <ButtonLink
                  icon="nav-locations"
                  url={config.APP_PATHS.editLocation(
                    selectedInvoice.location.id
                  )}
                >
                  <span>
                    {selectedInvoice.location.city}
                    <span className={styles.secondary}>
                      {selectedInvoice.location.address}
                    </span>
                  </span>
                </ButtonLink>
              </PaperNav>
            </div>
          )}
        </PaperContainer>
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  invoices: state.invoices,
  dropdowns: state.dropdowns,
});

InvoiceDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  invoices: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedInvoice: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(InvoiceDetails);
