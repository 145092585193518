import React, { useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getTime, startOfDay, endOfDay } from 'date-fns';
import DatePicker from 'react-datepicker';

import styles from './dateFilter.module.scss';
import { useClickOutside, formatDate } from '../../../utils';
import { Button, Icon } from '../..';
import RangeButton from './rangeButton/rangeButton';
import FilterTitle from '../_elements/filterTitle/filterTitle';

const DateFilter = ({
  title,
  lt,
  setLt,
  gt,
  setGt,
  onSubmit,
  sortOrder,
  setSortOrder,
  name,
  position,
  inLocations,
  isActive,
  titleWidth,
}) => {
  const filterRef = useRef();
  const [isOpen, setIsOpen] = useClickOutside(filterRef);
  const [currentRange, setCurrentRange] = useState({
    start: lt || 0,
    end: gt || 0,
  });

  const handleTitleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const handleOrderClick = () => {
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const handleSubmit = () => {
    onSubmit(name);
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const setRange = ({ start, end }) => {
    setGt(start);
    setLt(end);

    setCurrentRange({
      start: getTime(start),
      end: getTime(end),
    });
  };

  const datePickerChange = (date, setFilter, point) => {
    const timestamp = getTime(
      point === 'start' ? startOfDay(date) : endOfDay(date)
    );

    setFilter(timestamp);

    setCurrentRange({
      ...currentRange,
      [point]: timestamp,
    });
  };

  const rangeButtons = [
    { name: 'Šiandien', range: 'today' },
    { name: 'Vakar', range: 'yesterday' },
    { name: 'Rytoj', range: 'tomorrow' },
    { name: 'Ši savaitė', range: 'thisWeek' },
    { name: 'Praėjusi savaitė', range: 'lastWeek' },
    { name: 'Šis mėnuo', range: 'thisMonth' },
    { name: 'Praėjęs mėnuo', range: 'lastMonth' },
    { name: 'Šie metai', range: 'thisYear' },
    { name: 'Praėję metai', range: 'lastYear' },
  ];

  const renderRangeButtons = () =>
    rangeButtons.map(({ name, range }) => (
      <RangeButton
        key={name}
        name={name}
        range={range}
        setRange={setRange}
        currentRange={
          lt === undefined || lt === 0
            ? {
                start: 0,
                end: 0,
              }
            : currentRange
        }
      />
    ));

  return (
    <div className={styles.container} ref={filterRef}>
      {inLocations ? (
        <div className={styles.locationsHeader} onClick={handleTitleClick}>
          {lt || gt ? (
            `${formatDate(currentRange.start)} -
            ${formatDate(currentRange.end)}`
          ) : (
            <div className={cn(styles.title, styles.rangeTitle)}>
              <p>{title}</p>
              <Icon name="filter" classes={{ icon: styles.icon }} />
            </div>
          )}
        </div>
      ) : (
        <FilterTitle
          title={title}
          onTitleClick={handleTitleClick}
          isActive={isActive}
          customWidth={titleWidth}
        />
      )}

      {isOpen && (
        <div className={cn(styles.filter, styles[position])}>
          <div className={styles.datepicker}>
            <DatePicker
              className={styles.input}
              selected={gt}
              onChange={date => datePickerChange(date, setGt, 'start')}
              showYearDropdown
              placeholderText="Nuo"
              yearDropdownItemNumber={2}
              popperPlacement="bottom-center"
              dateFormat="yyyy.MM.dd"
            />
            <DatePicker
              className={styles.input}
              selected={lt}
              onChange={date => datePickerChange(date, setLt, 'end')}
              showYearDropdown
              placeholderText="Iki"
              yearDropdownItemNumber={2}
              popperPlacement="bottom-center"
              dateFormat="yyyy.MM.dd"
            />
          </div>

          <div className={styles.ranges}>{renderRangeButtons()}</div>

          <div className={styles.actions}>
            {!inLocations && (
              <Button
                color="light"
                classes={{ button: styles.button }}
                onClick={handleOrderClick}
              >
                {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
              </Button>
            )}
            <Button
              color="primary"
              classes={{ button: styles.button }}
              onClick={handleSubmit}
            >
              Filtruoti
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

DateFilter.defaultProps = {
  position: 'left',
  lt: undefined,
  gt: undefined,
  inLocations: false,
};

DateFilter.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lt: PropTypes.number,
  setLt: PropTypes.func.isRequired,
  gt: PropTypes.number,
  setGt: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired,
  setSortOrder: PropTypes.func.isRequired,
  position: PropTypes.string,
  inLocations: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
};

export default DateFilter;
