export const membershipsConstants = {
  SET_MEMBERSHIPS_LOADING: 'memberships/SET_MEMBERSHIPS_LOADING',
  GET_MEMBERSHIPS: 'memberships/GET_MEMBERSHIPS',
  SET_MEMBERSHIPS: 'memberships/SET_MEMBERSHIPS',
  GET_MEMBERSHIP: 'memberships/GET_MEMBERSHIP',
  SET_MEMBERSHIP: 'memberships/SET_MEMBERSHIP',
  EDIT_MEMBERSHIP: 'memberships/EDIT_MEMBERSHIP',
  CREATE_MEMBERSHIP: 'memberships/CREATE_MEMBERSHIP',
  ADD_MEMBERSHIP_HISTORY: 'memberships/ADD_MEMBERSHIP_HISTORY',
  EDIT_MEMBERSHIP_HISTORY: 'memberships/EDIT_MEMBERSHIP_HISTORY',
  DELETE_MEMBERSHIP_HISTORY: 'memberships/DELETE_MEMBERSHIP_HISTORY',
  CLEAR_MEMBERSHIPS: 'memberships/CLEAR_MEMBERSHIPS',
  DEACTIVATE_MEMBERSHIP: 'memberships/DEACTIVATE_MEMBERSHIP',
  EXPORT_MEMBERSHIPS: 'memberships/EXPORT_MEMBERSHIPS',
  EXPORT_MEMBERSHIPS_CLIENTS: 'memberships/EXPORT_MEMBERSHIPS_CLIENTS',
  ADD_MEMBERSHIP_ORDER_TO_CLIENT: 'memberships/ADD_MEMBERSHIP_ORDER_TO_CLIENT',
};
