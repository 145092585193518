import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '..';
import { config } from '../../api/config';
import { downloadFile, errorMessage } from '../../utils';

export function* getMembershipsSaga({ filter: filter }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    const memberships = yield call(Api.getMemberships, filter);

    yield put(actions.memberships.setMembershipsAction(memberships));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}

export function* getMembershipSaga({ id }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    const membership = yield call(Api.getMembership, id);

    yield put(actions.memberships.setMembershipAction(membership));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}

export function* editMembershipSaga({ id, body, history }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    yield call(Api.editMembership, id, body);

    history.push(config.APP_PATHS.memberships);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}
export function* createMembershipSaga({ body, history }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    yield call(Api.createMembership, body);

    history.push(config.APP_PATHS.memberships);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}

export function* addMembershipHistorySaga({ id, body }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    const response = yield call(Api.addMembershipHistory, id, body);
    yield put(actions.memberships.setMembershipAction(response));
    yield put(actions.modals.clearModals());
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}

export function* editMembershipHistorySaga({ id, historyId, body }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    const response = yield call(Api.editMembershipHistory, id, historyId, body);
    yield put(actions.memberships.setMembershipAction(response));
    yield put(actions.modals.clearModals());
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}

export function* deleteMembershipHistorySaga({ id, historyId }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    const response = yield call(Api.deleteMembershipHistory, id, historyId);
    yield put(actions.memberships.setMembershipAction(response));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}

export function* deactivateMembershipSaga({ id, newStatus }) {
  try {
    yield put(actions.memberships.setMembershipsLoadingAction(true));

    const body = { status: newStatus };

    yield call(Api.editMembership, id, body);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.memberships.setMembershipsLoadingAction(false));
  }
}

export function* exportMembershipsSaga({ filename }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportMemberships);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportMembershipsClientsSaga({ filename }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportMembershipsClients);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* addMembershipOrderToClientSaga({ body }) {
  try {
    const response = yield call(Api.addMembershipOrderToClient, body);
    yield put(actions.clients.setSelectedClientMembership(response));
    yield put(actions.modals.clearModals());
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  }
}
