import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { config } from '../../api/config';
import { actions } from '../../state';
import {
  formatMoney,
  formatFilename,
  formatDate,
  mapOnOffStatus,
  checkStatusColor,
  mapMembershipType,
  generateHeader,
  isFilterActive,
} from '../../utils';
import {
  Header,
  Title,
  ButtonDownload,
  Table,
  TableRow,
  TableCell,
  HeaderActions,
  Button,
  SelectFilter,
  RangeFilter,
  ButtonSimple,
} from '../../components';
import { MembershipsContext } from './memberships.context';

const Memberships = ({
  dispatch,
  memberships: { loading, memberships, header },
  xlsLoading,
}) => {
  const {
    statusFilter,
    setStatusFilter,
    typeFilter,
    setTypeFilter,
    nameFilter,
    setNameFilter,
    activeClientsLtFilter,
    setActiveClientsLtFilter,
    activeClientsGtFilter,
    setActiveClientsGtFilter,
    activeBusinessClientsLtFilter,
    setActiveBusinessClientsLtFilter,
    activeBusinessClientsGtFilter,
    setActiveBusinessClientsGtFilter,
    sortOrder,
    setSortOrder,
    filterBody,
    setFilterBody,
  } = useContext(MembershipsContext);

  const fetchMemberships = useCallback(
    data => {
      dispatch(actions.memberships.getMembershipsAction(data));
    },
    [dispatch]
  );

  const clearMemberships = useCallback(() => {
    dispatch(actions.memberships.clearMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMemberships(filterBody);
    return () => clearMemberships();
  }, [fetchMemberships, clearMemberships, filterBody]);

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.memberships);
    dispatch(actions.memberships.exportMembershipsAction(filename));
  };

  const xlsClientsExport = () => {
    const filename = formatFilename('Verslo planai');
    dispatch(actions.memberships.exportMembershipsClientsAction(filename));
  };

  const handleSubmit = name => {
    const generatedFilter = [];

    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }

    if (typeFilter.length) {
      generatedFilter.push({ field: 'type', value: typeFilter });
    }

    if (nameFilter.length) {
      generatedFilter.push({ field: 'title_lt', value: nameFilter });
    }

    if (activeClientsLtFilter) {
      generatedFilter.push({
        field: 'active_client_count',
        expression: 'lte',
        value: activeClientsLtFilter,
        type: 'number',
      });
    }
    if (activeClientsGtFilter) {
      generatedFilter.push({
        field: 'active_client_count',
        expression: 'gte',
        value: activeClientsGtFilter,
        type: 'number',
      });
    }

    if (activeBusinessClientsLtFilter) {
      generatedFilter.push({
        field: 'active_business_client_count',
        expression: 'lte',
        value: activeBusinessClientsLtFilter,
        type: 'number',
      });
    }
    if (activeBusinessClientsGtFilter) {
      generatedFilter.push({
        field: 'active_business_client_count',
        expression: 'gte',
        value: activeBusinessClientsGtFilter,
        type: 'number',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setStatusFilter([]);
    setTypeFilter([]);
    setNameFilter([]);
    setActiveClientsLtFilter('');
    setActiveClientsGtFilter('');
    setActiveBusinessClientsLtFilter('');
    setActiveBusinessClientsGtFilter('');

    handleSubmit();
  };

  const tableHeader = [
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapOnOffStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectFilter
      title="Tipas"
      name="type"
      valueType="number"
      array={generateHeader(header.type, mapMembershipType)}
      filterState={typeFilter}
      setFilterState={setTypeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', typeFilter)}
    />,
    <SelectFilter
      title="Pavadinimas (LT)"
      name="title_lt"
      valueType="string"
      array={generateHeader(header.title_lt)}
      filterState={nameFilter}
      setFilterState={setNameFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', nameFilter)}
    />,
    <RangeFilter
      title="Aktyvus fiziniams"
      name="active_client_count"
      lt={activeClientsLtFilter}
      setLt={setActiveClientsLtFilter}
      gt={activeClientsGtFilter}
      setGt={setActiveClientsGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        activeClientsLtFilter,
        activeClientsGtFilter
      )}
    />,
    <RangeFilter
      title="Aktyvus įmonėms"
      name="active_business_client_count"
      lt={activeBusinessClientsLtFilter}
      setLt={setActiveBusinessClientsLtFilter}
      gt={activeBusinessClientsGtFilter}
      setGt={setActiveBusinessClientsGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        activeBusinessClientsLtFilter,
        activeBusinessClientsGtFilter
      )}
    />,
  ];

  const renderTable = () =>
    memberships.map(
      ({
        id,
        status,
        type,
        title_lt,
        active_client_count,
        active_business_client_count,
      }) => (
        <TableRow url={config.APP_PATHS.editMembership(id)} key={id}>
          <TableCell>
            <span style={{ color: checkStatusColor(status) }}>
              {mapOnOffStatus(status)}
            </span>
          </TableCell>
          <TableCell>{mapMembershipType(type)}</TableCell>
          <TableCell>{title_lt}</TableCell>
          <TableCell>{active_client_count}</TableCell>
          <TableCell>{active_business_client_count}</TableCell>
        </TableRow>
      )
    );

  return (
    <section>
      <Header>
        <Title total={memberships.length}>{config.PAGES.memberships}</Title>

        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          <ButtonDownload
            onClick={xlsExport}
            loading={xlsLoading}
            title="Nuolaidų planai"
          />
          <ButtonDownload
            onClick={xlsClientsExport}
            loading={xlsLoading}
            title="Verslo planai"
          />
          <Button color="primary" url={config.APP_PATHS.addMembership}>
            Sukurti
          </Button>
        </HeaderActions>
      </Header>

      <Table
        header={tableHeader}
        isEmpty={!memberships.length}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  memberships: state.memberships,
  xlsLoading: state.download.xlsLoading,
});

Memberships.propTypes = {
  dispatch: PropTypes.func.isRequired,
  memberships: PropTypes.shape({
    memberships: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Memberships);
