import request from 'request-promise';
import { select } from 'redux-saga/effects';
import { selectIsImpersonating } from '../state';
import { config } from './config';
import { getLocalStorageItem, getLocalStorageItemIfNotExpired } from '../utils';

export class Api {
  static API_BASE_URL =
    process.env.NODE_ENV === 'production'
      ? config.API_BASE_URL_PROD
      : config.API_BASE_URL_DEV;

  static BASE_URL = Api.API_BASE_URL + config.API_BASE_VERSION;

  static BASE_SOCKET_HOST =
    process.env.NODE_ENV === 'production'
      ? config.API_BASE_SOCKET_HOST_PROD
      : config.API_BASE_SOCKET_HOST_DEV;

  static ENDPOINTS = config.API_ENDPOINTS;

  /* UTILS */

  static getTokenFromLocalStorage() {
    const key = config.LOCAL_STORAGE.isImpersonating;
    var isImpersonating = getLocalStorageItem(key);

    if (isImpersonating) {
      const { impersonationToken } = config.LOCAL_STORAGE;

      const token = getLocalStorageItem(impersonationToken);
      return token;
    } else {
      const {
        adminToken,
        adminTokenSetupTime,
        adminTokenExpiryHours,
      } = config.LOCAL_STORAGE;

      const token = getLocalStorageItemIfNotExpired(
        adminToken,
        adminTokenSetupTime,
        adminTokenExpiryHours
      );

      return token;
    }
  }

  static async fetchApiWithAuth(options) {
    const token = Api.getTokenFromLocalStorage();
    const finalOptions = {
      headers: { Authorization: `Bearer ${token}` },
      method: options.method,
      body: options.body,
    };

    if (token) return await fetch(options.url, finalOptions);
    else throw new Error('Authentication failed');
  }

  static async withAuth(options) {
    const token = Api.getTokenFromLocalStorage();
    const finalOptions = {
      json: true,
      headers: { Authorization: `Bearer ${token}` },
      ...options,
    };

    if (token) {
      return await request(finalOptions);
    } else {
      throw new Error('Authentication Failed');
    }
  }

  static async withoutAuth(options) {
    const finalOptions = {
      json: true,
      ...options,
    };
    return await request(finalOptions);
  }

  /* LOV Header */

  static async searchHeader(query, params) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.searchHeader(params),
      body: {
        search_query: query,
      },
    };
    return await Api.withAuth(options);
  }

  /* AUTH */

  static async getToken(credentials) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.login,
      body: credentials,
    };
    return await Api.withoutAuth(options);
  }

  static async impersonateBusinessClient(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.impersonate(id),
    };
    return await Api.withAuth(options);
  }

  static async getCurrentAdmin(token) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getCurrentAdmin,
      headers: { Authorization: `Bearer ${token}` },
    };
    return await Api.withoutAuth(options);
  }

  static async verify2faCode(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.verify2faCode,
      body,
    };
    return await Api.withoutAuth(options);
  }

  verify2faCode;

  static async logoutUser() {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.logout,
    };
    return await Api.withAuth(options);
  }

  static async updatePass(body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.updatePass,
      body,
    };
    return await Api.withAuth(options);
  }

  static async resetPassRequest(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.resetPassRequest,
      body,
    };
    return await Api.withoutAuth(options);
  }

  static async resetPassFromToken(body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.resetPassFromToken,
      body,
    };
    return await Api.withoutAuth(options);
  }

  /* ADMINS */

  static async getAdmins() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.admins,
    };
    return await Api.withAuth(options);
  }

  static async getFilteredAdmins(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.admins,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getAdminsManagers(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.adminsManagers,
      body,
    };
    return await Api.withAuth(options);
  }

  static async searchCompanyDetails(query) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.searchCompanyDetails(query),
    };
    return await Api.withAuth(options);
  }

  static async getSingleCompanyDetails(code) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.singleCompanyDetails(code),
    };
    return await Api.withAuth(options);
  }

  static async getAdmin(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getAdmin(id),
    };
    return await Api.withAuth(options);
  }

  static async addAdmin(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addAdmin,
      body,
    };
    return await Api.withAuth(options);
  }

  static async editAdmin(id, body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.editAdmin(id),
      body,
    };
    return await Api.withAuth(options);
  }

  /* CLIENTS */

  static async getClients(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.clients,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getClient(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getClient(id),
    };
    return await Api.withAuth(options);
  }

  static async updateClient(id, body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.updateClient(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async deleteClientData(id) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.deleteClientData(id),
    };
    return await Api.withAuth(options);
  }

  static async topUpWallet(clientId, amount) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.topUpWallet(clientId),
      body: { amount },
    };
    return await Api.withAuth(options);
  }

  static async exportClients() {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportClients,
    };
    return await Api.withAuth(options);
  }

  static async searchClients(query) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.searchClients(query),
    };
    return await Api.withAuth(options);
  }

  static async searchH2Cards(query) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.searchH2Cards(query),
    };
    return await Api.withAuth(options);
  }

  static async getClientCards(clientId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getClientCards(clientId),
    };
    return await Api.withAuth(options);
  }

  /* BUSINESS CLIENTS */

  static async getBusinessClients(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.businessClients,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getBusinessClient(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getBusinessClient(id),
    };
    return await Api.withAuth(options);
  }

  static async getBusinessClientsMemberships(business_client_id) {
    const options = {
      method: 'GET',
      uri:
        Api.BASE_URL +
        Api.ENDPOINTS.getBusinessClientMemberships(business_client_id),
    };

    return await Api.withAuth(options);
  }

  static async updateBusinessClient(id, body) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.updateBusinessClient(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async addBusinessClient(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addBusinessClient,
      body,
    };
    return await Api.withAuth(options);
  }

  static async addBusinessClientsMembership(id, body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addBusinessClientsMembership(id),
      body,
    };

    return await Api.withAuth(options);
  }

  static async updateBusinessClientsMembership(
    businessClientId,
    membershipId,
    body
  ) {
    const options = {
      method: 'PUT',
      url:
        Api.BASE_URL +
        Api.ENDPOINTS.updateBusinessClientsMembership(
          businessClientId,
          membershipId
        ),
      body,
    };

    return await Api.withAuth(options);
  }

  static async exportBusinessClients(body) {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportBusinessClients,
      body,
    };
    return await Api.withAuth(options);
  }

  /* LOCATIONS */

  static async getLocations(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.locations,
      body,
    };
    return await Api.withAuth(options);
  }

  static async addLocations(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addLocations,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getLocation(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getLocation(id),
    };
    return await Api.withAuth(options);
  }

  static async updateLocation(body) {
    const id = body.id;
    delete body.id;
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.updateLocation(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async regenerateKey(id) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.regenerateKey(id),
    };
    return await Api.withAuth(options);
  }

  static async editLocation(id, body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.editLocation(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportLocations(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportLocations,
      body,
    };
    return await Api.withAuth(options);
  }

  static async downloadLocationsQr(id) {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.downloadLocationsQr(id),
    };
    return await Api.withAuth(options);
  }

  /* DISCOUNTS */

  static async getDiscounts(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.discounts,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getDiscount(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getDiscount(id),
    };
    return await Api.withAuth(options);
  }

  static async addDiscount(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addDiscount,
      body,
    };

    return await Api.withAuth(options);
  }

  static async editDiscount(id, body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.editDiscount(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async deactivateDiscount(id) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.deactivateDiscount(id),
    };
    return await Api.withAuth(options);
  }

  static async exportDiscounts() {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportDiscounts,
    };
    return await Api.withAuth(options);
  }

  static async uploadDiscountBanner(file) {
    const fd = new FormData();
    fd.append('image', file);

    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.uploadBannerImage,
      body: fd,
    };
    const response = await Api.fetchApiWithAuth(options);
    return await response.json();
  }

  /* ORDERS */

  static async getOrders(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.orders,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getOrder(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getOrder(id),
    };
    return await Api.withAuth(options);
  }

  static async exportOrders(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportOrders,
      body,
    };
    return await Api.withAuth(options);
  }

  static async retryPayment(orderId, paymentId) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.retryPayment(orderId),
      body: {
        payment_card_id: paymentId,
      },
    };
    return await Api.withAuth(options);
  }

  static async markAsPaid(orderId) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.markAsPaid(orderId),
    };
    return await Api.withAuth(options);
  }

  /* MEMBERSHIPS */

  static async getMemberships(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.memberships,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getMembership(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getMembership(id),
    };
    return await Api.withAuth(options);
  }

  static async editMembership(id, body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.editMembership(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async createMembership(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.createMembership,
      body,
    };
    return await Api.withAuth(options);
  }

  static async addMembershipHistory(id, body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addMembershipHistory(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async editMembershipHistory(id, historyId, body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.editMembershipHistory(id, historyId),
      body,
    };
    return await Api.withAuth(options);
  }

  static async deleteMembershipHistory(id, historyId) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.deleteMembershipHistory(id, historyId),
    };
    return await Api.withAuth(options);
  }

  static async exportMemberships() {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportMemberships,
    };
    return await Api.withAuth(options);
  }

  static async exportMembershipsClients() {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportMembershipsClients,
    };
    return await Api.withAuth(options);
  }

  static async addMembershipOrderToClient(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addMembershipOrderToClient,
      body,
    };
    return await Api.withAuth(options);
  }

  /* MEMBERSHIP ORDERS */

  static async getMembershipOrders(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.membershipOrders,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getMembershipOrder(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getMembershipOrder(id),
    };
    return await Api.withAuth(options);
  }

  static async deactivateMembershipOrder(id) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.deactivateMembershipOrder(id),
    };
    return await Api.withAuth(options);
  }

  static async exportMembershipOrders(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportMembershipOrders,
      body,
    };
    return await Api.withAuth(options);
  }

  /* INVOICES */

  static async getInvoices(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getInvoices,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getInvoice(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getInvoice(id),
    };
    return await Api.withAuth(options);
  }

  static async exportInvoices(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportInvoices,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportInvoicesAsync(filter) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportInvoicesAsync,
      body: filter,
    };
    return await Api.withAuth(options);
  }

  static async exportOrdersAsync(filter) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportOrdersAsync,
      body: filter,
    };
    return await Api.withAuth(options);
  }

  static async exportClientsAsync(filter) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportClientsAsync,
      body: filter,
    };
    return await Api.withAuth(options);
  }

  static async exportReceiptsAsync(filter) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportReceiptsAsync,
      body: filter,
    };
    return await Api.withAuth(options);
  }

  static async exportInvoicesHeads(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportInvoicesHeads,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportInvoicesBodies(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportInvoicesBodies,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportInvoicesPartners(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportInvoicesPartners,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportInvoicesXml(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportInvoicesXml,
      body,
    };
    return await Api.withAuth(options);
  }

  static async generateMonthlyInvoices() {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.generateMonthlyInvoices,
    };
    return await Api.withAuth(options);
  }

  static async updateInvoice(id, body) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.updateInvoice(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async downloadInvoicePdf(id) {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.downloadInvoicePdf(id, 'lt'),
    };

    return await Api.withAuth(options);
  }

  static async getObjectNumbers() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.objectNumbers,
    };
    return await Api.withAuth(options);
  }

  /* RECEIPTS */
  static async getReceipts(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getReceipts,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getReceipt(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getReceipt(id),
    };
    return await Api.withAuth(options);
  }

  static async exportReceipts(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportReceipts,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportReceiptsXml(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportReceiptsXml,
      body,
    };
    return await Api.withAuth(options);
  }

  /* BOXES */

  static async checkBoxStatus(ip) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.pingDevice,
      body: { ip },
    };
    return await Api.withAuth(options);
  }

  static async getBoxesTableData(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getBoxestableData,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportBoxes(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportBoxes,
      body,
    };
    return await Api.withAuth(options);
  }

  /* SERVICES */

  static async getServicesTableData(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getServicestableData,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportServices(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportServices,
      body,
    };
    return await Api.withAuth(options);
  }

  /* FILE UPLOAD */

  static async uploadBusinessClientFile(file) {
    const fd = new FormData();
    fd.append('file', file);

    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.fileUpload,
      body: fd,
    };
    const response = await Api.fetchApiWithAuth(options);
    return await response.json();
  }

  static async uploadBusinessClientImage(file) {
    const fd = new FormData();
    fd.append('image', file);

    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.imageUpload,
      body: fd,
    };
    const response = await Api.fetchApiWithAuth(options);
    return await response.json();
  }

  /* TEXTILE */
  static async getAutomaticWashCodesTable(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getAutomaticWashCodes,
      body,
    };
    return await Api.withAuth(options);
  }

  static async useAutomaticWashCode(id) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.useAutomaticWashCode(id),
    };

    return await Api.withAuth(options);
  }

  static async removeAutomaticWashCode(id) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.cancelAutomaticWashCode(id),
    };

    return await Api.withAuth(options);
  }

  /* CAPMAIGNS */

  static async getCampaigns(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.campaignNotifications,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getCampaign(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getCampaignNotification(id),
    };
    return await Api.withAuth(options);
  }

  static async addCampaign(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addCampaignNotification,
      body,
    };

    return await Api.withAuth(options);
  }

  static async editCampaign(id, body) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.editCampaignNotification(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async getCampaignPotentialAudience(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.campaignNotificationCountAudience,
      body,
    };

    return await Api.withAuth(options);
  }

  /* BA: EMPLOYEES */

  static async getEmployees(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getBaEmployees,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getEmployee(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getBaEmployee(id),
    };
    return await Api.withAuth(options);
  }

  static async getH2Card(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getH2Card(id),
    };
    return await Api.withAuth(options);
  }

  static async addEmployee(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addBaEmployee,
      body,
    };
    return await Api.withAuth(options);
  }

  static async addH2Card(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.addH2Card,
      body,
    };
    return await Api.withAuth(options);
  }

  static async updateEmployee(id, body) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.updateBaEmployee(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async updateH2Card(id, body) {
    const options = {
      method: 'PATCH',
      url: Api.BASE_URL + Api.ENDPOINTS.updateH2Card(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async employeesBulkMembershipsAssign(body) {
    const options = {
      method: 'PUT',
      url: Api.BASE_URL + Api.ENDPOINTS.bulkMemberships,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportEmployees(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportEmployees,
      body,
    };
    return await Api.withAuth(options);
  }

  /* BA: ORDERS */
  static async getBusinessClientOrders(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getBaOrderHistory,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getBusinessClientOrder(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getBaOrderDetails(id),
    };
    return await Api.withAuth(options);
  }

  static async exportH2CardAsync(filter) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportH2CardAsync,
      body: filter,
    };
    return await Api.withAuth(options);
  }

  /* BA: INVOICES */
  static async getBusinessInvoices(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getBaInvoices,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getBusinessInvoice(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getBaInvoice(id),
    };
    return await Api.withAuth(options);
  }

  /* COUPONS */
  static async getCoupons(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getCoupons,
      body,
    };
    return await Api.withAuth(options);
  }

  static async exportCoupons(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportCoupons,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getCoupon(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getCoupon(id),
    };
    return await Api.withAuth(options);
  }

  static async exportCouponsCodeByCode(couponId) {
    const options = {
      method: 'GET',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.exportCouponsCodeByCode(couponId),
    };
    return await Api.withAuth(options);
  }

  /* COUNTRIES */
  static async getCountries() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.countries,
    };
    return await Api.withAuth(options);
  }
}
