import React, { useState, useMemo } from 'react';
import { Button, Input, Select } from '../../components';
import { actions } from '../../state';
import styles from './invoices.module.scss';
import { formatMoneyToCents } from '../../utils';

const EditEntry = props => {
  const { invoiceId, entries, selectedEntry, dispatch, objectNumbers } = props;

  const [values, setValues] = useState({
    item: selectedEntry?.item || '',
    quantity: selectedEntry?.quantity,
    unit: selectedEntry?.unit || '',
    cost: selectedEntry?.cost ? selectedEntry?.cost / 100 : '',
    object_number: selectedEntry?.object_number || '',
  });

  const rawObjectNumbers = useMemo(
    () =>
      objectNumbers?.length
        ? objectNumbers.map(number => {
            return {
              name: number.name,
              value: number.object_number,
            };
          })
        : [],
    [objectNumbers]
  );

  const objectNumbersList = [
    {
      name: 'Nepasirinkta',
      value: '',
    },
    ...(rawObjectNumbers?.length ? rawObjectNumbers : []),
  ];

  const removeIds = entries => {
    return entries?.length
      ? entries.map(entry => {
          return { ...entry, id: undefined };
        })
      : [];
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setValues(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    // edit
    if (selectedEntry) {
      const selectedEntryId = selectedEntry.id;

      const editedEntry = {
        id: selectedEntryId,
        item: values.item,
        quantity: Number(values.quantity),
        unit: values.unit,
        cost: formatMoneyToCents(values.cost),
        object_number: values.object_number,
      };

      const payload = entries.map(entry => {
        return selectedEntryId === entry.id ? editedEntry : entry;
      });

      const body = {
        entries: removeIds(payload),
      };
      dispatch(actions.invoices.updateInvoiceAction(invoiceId, body));
      return;
    }

    // new
    const body = {
      entries: [
        ...removeIds(entries),
        {
          item: values.item,
          quantity: Number(values.quantity),
          unit: values.unit,
          cost: formatMoneyToCents(values.cost),
          object_number: values.object_number,
        },
      ],
    };
    dispatch(actions.invoices.updateInvoiceAction(invoiceId, body));
  };

  const handleRemove = e => {
    e.preventDefault();
    const selectedEntryId = selectedEntry.id;
    const filteredEntries = entries.filter(
      entry => entry.id !== selectedEntryId
    );

    const body = {
      entries: removeIds(filteredEntries),
    };
    dispatch(actions.invoices.updateInvoiceAction(invoiceId, body));
  };

  const info = [
    {
      title: 'Prekė',
      data: (
        <Input
          name="item"
          label=""
          type="text"
          value={values.item}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Eilutės tipas',
      data: (
        <div className={styles.selectInput}>
          <Select
            name="object_number"
            options={objectNumbersList}
            selectedValue={values.object_number}
            onChange={handleInputChange}
          />
        </div>
      ),
    },
    {
      title: 'Kiekis',
      data: (
        <Input
          name="quantity"
          type="number"
          min="1"
          step="1"
          onChange={handleInputChange}
          value={values.quantity}
        />
      ),
    },
    {
      title: 'Mat. vnt.',
      data: (
        <Input
          name="unit"
          label=""
          type="text"
          value={values.unit}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Suma, eur (su PVM)',
      data: (
        <Input
          name="cost"
          type="number"
          placeholder="0"
          step="0.01"
          onChange={handleInputChange}
          value={values.cost}
        />
      ),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, hide, data }) =>
      hide ? (
        <></>
      ) : (
        <div className={styles.editEntryInfoRow} key={title}>
          <span className="title">{title}</span>
          <span className="height free-width">{data}</span>
        </div>
      )
    );
  return (
    <form onSubmit={handleSubmit} method="POST">
      <div className={styles.info}>{renderInfo()}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '2rem',
        }}
      >
        <Button
          color="dim"
          classes={{ button: styles.saveEntry }}
          onClick={handleRemove}
        >
          Ištrinti
        </Button>
        <Button
          color="primary"
          type="submit"
          classes={{ button: styles.saveEntry }}
        >
          Išsaugoti
        </Button>
      </div>
    </form>
  );
};

export default EditEntry;
