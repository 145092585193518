import { constants } from '..';

const setMembershipsLoadingAction = flag => ({
  type: constants.memberships.SET_MEMBERSHIPS_LOADING,
  flag,
});

const getMembershipsAction = data => ({
  type: constants.memberships.GET_MEMBERSHIPS,
  filter: data,
});

const setMembershipsAction = memberships => ({
  type: constants.memberships.SET_MEMBERSHIPS,
  memberships,
});

const getMembershipAction = id => ({
  type: constants.memberships.GET_MEMBERSHIP,
  id,
});

const setMembershipAction = membership => ({
  type: constants.memberships.SET_MEMBERSHIP,
  membership,
});

const editMembershipAction = (id, body, history) => ({
  type: constants.memberships.EDIT_MEMBERSHIP,
  id,
  body,
  history,
});

const createMembershipAction = (body, history) => ({
  type: constants.memberships.CREATE_MEMBERSHIP,
  body,
  history,
});

const deactivateMembershipAction = (id, newStatus) => ({
  type: constants.memberships.DEACTIVATE_MEMBERSHIP,
  id,
  newStatus,
});

const clearMembershipsAction = () => ({
  type: constants.memberships.CLEAR_MEMBERSHIPS,
});

const addMembershipHistoryAction = (id, body) => ({
  type: constants.memberships.ADD_MEMBERSHIP_HISTORY,
  id,
  body,
});

const editMembershipHistoryAction = (id, historyId, body) => ({
  type: constants.memberships.EDIT_MEMBERSHIP_HISTORY,
  id,
  historyId,
  body,
});

const deleteMembershipHistoryAction = (id, historyId) => ({
  type: constants.memberships.DELETE_MEMBERSHIP_HISTORY,
  id,
  historyId,
});

const exportMembershipsAction = filename => ({
  type: constants.memberships.EXPORT_MEMBERSHIPS,
  filename,
});

const exportMembershipsClientsAction = filename => ({
  type: constants.memberships.EXPORT_MEMBERSHIPS_CLIENTS,
  filename,
});

const addMembershipOrderToClient = body => ({
  type: constants.memberships.ADD_MEMBERSHIP_ORDER_TO_CLIENT,
  body,
});

export const membershipsActions = {
  setMembershipsLoadingAction,
  getMembershipsAction,
  setMembershipsAction,
  getMembershipAction,
  setMembershipAction,
  editMembershipAction,
  createMembershipAction,
  deactivateMembershipAction,
  clearMembershipsAction,
  addMembershipHistoryAction,
  editMembershipHistoryAction,
  deleteMembershipHistoryAction,
  exportMembershipsAction,
  exportMembershipsClientsAction,
  addMembershipOrderToClient,
};
